<template>
  <el-card>
    <el-descriptions title="系统版本" :column="1">
      <el-descriptions-item label="前端版本">{{
        info.frontVer
      }}</el-descriptions-item>
      <el-descriptions-item label="后台版本">{{
        info.backVer
      }}</el-descriptions-item>
    </el-descriptions>
  </el-card>
</template>

<script>
import { getInfo } from "@/api/system/version.js";

export default {
  data() {
    return {
      info: {},
    };
  },
  created() {
    getInfo().then((res) => {
      this.info = res;
      this.info.frontVer = process.env.VUE_APP_VER;
    });
  },
};
</script>

<style></style>
